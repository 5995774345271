<template>
	<section class="section-wrapper" v-background="backgroundOverlay">
		<base-container>
				<base-heading :heading="`${prefix}.title`" :subheading="`${prefix}.subtitle`"/>
				<div class="wrapper">
					<div class="product-types">
						<button
							v-for="(item, index) in value.product"
							:key="item.product_name"
							class="product-button"
							@click="chooseTab(index)"
							:class="{active: index === activeTab}"
						>
						{{item.product_name}}
						</button>
					</div>
					<div
						class="product-content"
						v-for="(item, index) in value.product"
						:key="item.product_name"
						:class="{active: index === activeTab}"
					>
						<client-only>
							<cms-text :value="`${prefix}.product.${index}.description`"/>
						</client-only>
						<figure class="image-wrapper">
							<cms-image
								:src="item.image"
								:base="{height: 200}"
								:md="{height: 220}"
                                :lg="{height: 180}"
								:xxl="{height: 270}"
								class="product-image"
							/>
						</figure>
					</div>
				</div>
		</base-container>
	</section>
</template>
<script>
import ClientOnly from 'vue-client-only'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
export default {
	props: {
		prefix: String,
		value: Object
	},
	components: {
		BaseHeading,
		ClientOnly
	},
	data: () => ({
		activeTab: 0
	}),
	computed: {
		backgroundOverlay () {
			const path = '/products/bg-overlay.jpg'
			return {
				src: path,
				breakpoints: {
					base: { width: 720, height: 650 },
					lg: { width: 825, height: 451 },
					xl: { width: 1920, height: 515 }
				}
			}
		}
	},
	methods: {
		chooseTab (index) {
			this.activeTab = index
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding: 4rem 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	@include media-breakpoint-up(xl) {
		padding: 8rem 0;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 3rem;
            margin-bottom: 14rem;
		}
        @include media-breakpoint-up(xl) {
            margin-bottom: 4rem;
        }
         @include media-breakpoint-up(xl) {
            margin-bottom: 14rem;
        }
	}
	.product-types {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex: 0 1 25%;
		min-width: 210px;
		@include media-breakpoint-up(md) {
			flex: 1 1 50%;
		}
		@include media-breakpoint-up(xl) {
			flex: 0 1 33%;
		}
		.product-button {
			background: $white;
			border: none;
			outline: none;
			width: 100%;
			text-transform: uppercase;
			font-weight: 700;
			color: $gray-500;
			text-align: left;
			cursor: pointer;
			border-left: 4px solid $white;
			transition: .3s ease-in;
			padding: 1.5rem 1rem;
			@include media-breakpoint-up(xl) {
				padding: 2.5rem;
			}
			@include media-breakpoint-up(xxl) {
				font-size: 1.9rem;
				padding: 3rem;
			}
			&.active {
				border-left: 4px solid $secondary-300;
				color: $secondary-300;
			}

			&:not(:last-of-type) {
				border-bottom: 1px solid $gray-200;
			}
		}
	}
	.product-content {
		background: $white;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		display: none;
		padding: 1.5rem;
		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}
		@include media-breakpoint-up(xl) {
			gap: 2rem;
			padding: 4rem 0 4rem 4rem;
			width: 690px;
			min-height: 262px;
		}

		&.active {
			display: flex;
		}
	}
	.image-wrapper {
		position: relative;
		flex: 1 0 auto;
		margin: 0;
		@include media-breakpoint-up(xl) {
			width: 270px;
		}

		::v-deep .base-image {
			@include media-breakpoint-up(xl) {
				position: absolute;
				top: 50%;
				right: 0;
                transform: translate(15%, -50%);
			}
            @include media-breakpoint-up(xxl){
				transform: translate(50%, -50%);
            }
		}
	}
}

</style>
